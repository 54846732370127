<template>
  <with-side-navigations side="left">
    <template #sideNavigation>
      <admins-sidebar />
    </template>
    <div>
      <div class="flex flex-wrap gap-2 items-center justify-between">
        <div class="flex items-center">
          <h1 class="text-3xl">{{ $t('employee-list-view.employees') }}</h1>
          <pagination-select :query="routeQuery" @select-limit="selectLimit" />
          <filter-toggler class="ml-4" />
        </div>
        <router-link :to="{ name: 'AddAdmin' }">
          <btn class="w-26">{{ $t('common.add') }}</btn>
        </router-link>
      </div>
      <table-list
        content="users"
        :query="computedQuery"
        route-component="Admins"
        class="pb-16"
        :force-update="listUpdatesCounter"
        :cols="{
          firstName: $t('employee-list-view.first-name'),
          lastName: $t('employee-list-view.last-name'),
          email: { label: $t('employee-list-view.e-mail'), formatter: email },
          phone: $t('employee-list-view.phone'),
        }"
        :actions="{
          ...(showUserPreview && {
            preview: (id) => previewUser(id, 'Admins'),
          }),
          edit: onEditBtnClick,
          delete: {
            handler: onRemoveBtnClick,
            modal: (user) => ({
              type: 'confirm',
              title: $t('employee-list-view.modal-title'),
              description: $t('employee-list-view.modal-description', {
                name: `${user.firstName} ${user.lastName}`,
              }),
              btnText: $t('employee-list-view.modal-btn'),
            }),
          },
        }"
        checkbox
        :selected-ids="selectedUsers"
        @select="selectedUsers = $event"
      ></table-list>
    </div>
  </with-side-navigations>
</template>

<script>
import Btn from '@/components/Button/Button.vue';
import FilterToggler from '@/components/Filters/FilterToggler.vue';
import WithSideNavigations from '@/layouts/WithSideNavigations.vue';
import AdminsSidebar from '@/views/Dashboard/Admins/AdminsSidebar.vue';
import TableList from '@/components/Table/TableList.vue';
import { PaginationSelect } from '@/components/Inputs';

import { email } from '@/util/formatters';
import { mapActions } from 'vuex';
import routeQuery from '@/mixins/query/routeQuery';
import { SYSTEM_ROLES } from '@/store/modules/user';
import { useViewer } from '@/composables/use-viewer';

export default {
  components: {
    Btn,
    FilterToggler,
    TableList,
    WithSideNavigations,
    AdminsSidebar,
    PaginationSelect,
  },
  mixins: [routeQuery],
  data() {
    return {
      search: '',
      listUpdatesCounter: 0,
      selectedUsers: [],
    };
  },
  computed: {
    computedQuery() {
      return {
        systemRole: SYSTEM_ROLES.ADMIN,
      };
    },
  },
  methods: {
    ...mapActions('common', ['delete']),
    onEditBtnClick(id) {
      this.$router.push({ name: 'EditAdmin', params: { id } });
    },
    async onRemoveBtnClick(id) {
      await this.delete({ type: 'users', id });
      this.listUpdatesCounter++;
      this.$toast.success(this.$t('employee-list-view.removed-successfully'));
    },
    email,
  },
  setup(props, ctx) {
    const { previewUser, showUserPreview } = useViewer(ctx);

    return { showUserPreview, previewUser };
  },
};
</script>
